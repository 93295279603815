import { GetServerDataReturn } from 'gatsby';
import React from 'react';

import DocumentsPage from '../components/DocumentsPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IDocumentsPage, IDocument } from '../types/strapi/documents';
import { IGlobalServerRuntimeData } from '../types/strapi/global';

type TProp = TPageProp<IServerRuntimeData>;

const Documents: React.FC<TProp> = ({ serverData }) => {
    return (
        <DocumentsPage data={serverData} />
    );
};

export default Documents;

const query = `
  query DocumentsPage ($locale: String) {
    ${GLOBAL_QUERY}
    documentsPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      headerText
    }
    documents(locale: $locale, sort: "sort:asc") {
      title
      type
      file {
        url
      }
      url
    }
  }
`;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    documentsPage: IDocumentsPage,
    documents: Array<IDocument>
}

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });

        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                navPanel     : data.data.navPanel,
                global       : data.data.global,
                respondForm  : data.data.respondForm,
                footer       : data.data.footer,
                documentsPage: data.data.documentsPage,
                documents    : data.data.documents
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
